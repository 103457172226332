<div class="header-container" [class.login]="isLogin">
  <div class="d-flex align-items-center crumbs-contain">
    <sm-breadcrumbs
      class="spacer"
      [class.share-view]="isShareMode"
      [activeWorkspace]="activeWorkspace">
    </sm-breadcrumbs>
    <div class="ml-3 d-flex align-items-center" *ngIf="userFocus || (showUserFocus$ | async)">
      <sm-show-only-user-work></sm-show-only-user-work>
    </div>
  </div>

  <div *ngIf="isDashboard && !isLogin" class="logo-full middle"
       [class.make-room-for-slogan]="environment.whiteLabelSlogan">
    <img alt="logo" [src]="environment?.branding?.logo" width="122" height="42">
    <div *ngIf="environment.whiteLabelSlogan" class="slogan">{{environment.whiteLabelSlogan}}</div>
  </div>
  <div *ngIf="isLogin && environment.whiteLabelLogo" class="logo-full">
    <img alt="logo" [src]="environment?.branding?.logo" width="122" height="42">
  </div>
  <div *ngIf="isLogin" class="spacer"></div>

  <div class="right-buttons">
    <sm-common-search [class.share-view]="isShareMode"></sm-common-search>

    <a class="pointer resources-trigger" [matMenuTriggerFor]="resourcesMenu">
      <i class="far al-ico-help-outlined"></i>
    </a>
    <a class="pointer menu-trigger position-relative" [matMenuTriggerFor]="profileMenu">
      <img alt="avatar" class="avatar" *ngIf="(user | async).avatar; else iconAvatar" [src]="(user | async).avatar">
      <ng-template #iconAvatar>
        <div class="user-icon">
          <i class="al-icon al-ico-account sm-md"></i>
        </div>
      </ng-template>
      <div *ngIf="userNotificationPath || (invitesPending$ | async)?.length" class="user-notification"></div>
    </a>

    <mat-menu #profileMenu="matMenu" class="user-menu">
      <button mat-menu-item [routerLink]="'settings/' + userNotificationPath">
        <span class="al-icon icon sm-md" [class]="userNotificationPath ? 'al-ico-settings-alert' : 'al-ico-settings'">
          <span class="path1"></span><span class="path2"></span>
        </span>
        Settings
      </button>
      <sm-header-user-menu-actions></sm-header-user-menu-actions>
      <button mat-menu-item (click)="logout()">
        <span class="al-ico-logout al-icon icon sm-md"></span>
        Logout
      </button>
    </mat-menu>

    <mat-menu #resourcesMenu="matMenu" class="user-menu">
      <button mat-menu-item (click)="openWelcome($event)">
        <i class="al-icon sm-md al-ico-code-file"></i>ClearML Python Package setup
      </button>
      <button mat-menu-item *ngIf="environment.slackLink" (click)="navigate(environment.slackLink)">
        <i class="al-icon al-ico-slack sm-md"></i>Community support on Slack
      </button>
      <button mat-menu-item (click)="navigate('https://www.youtube.com/c/ClearML/featured')">
        <i class="al-icon al-ico-youtube sm-md"></i>ClearML on Youtube
      </button>
      <button mat-menu-item (click)="navigate(environment.docsLink)">
        <i class="al-icon sm-md al-ico-documentation"></i>Online Documentation
      </button>
      <button mat-menu-item (click)="openTip()">
        <i class="al-icon sm-md al-ico-tips"></i>Pro Tips
      </button>
      <ng-container *smCheckPermission="'applications'">
        <button mat-menu-item
                *ngIf="$any(environment)?.appsYouTubeIntroVideoId && $any(environment).appAwarenessMenu !== false"
                (click)="openAppsAwareness($event, $any(environment)?.appsYouTubeIntroVideoId)"
        >
          <i class="al-icon sm-md al-ico-applications"></i>ClearML Apps Introduction
        </button>
      </ng-container>
      <button mat-menu-item (click)="navigate('https://www.clear.ml/contact-us/')">
        <i class="al-icon sm-md al-ico-email"></i>Contact Us
      </button>
    </mat-menu>
  </div>
</div>
<ng-content></ng-content>
