<sm-dialog-template header="SELECT A PUBLIC MODEL">
  <div class="model-table-container">
    <div class="w-100 model-header">
      <sm-select-model-header
        [searchActive]="true"
        [searchValue]="searchValue$ | async"
        [isAllProjects]="isAllProjects$ | async"
        [viewMode]="viewMode$ | async"
        [hideArchiveToggle]="true"
        [hideCreateNewButton]="true"
        [showAllProjectsToggle]="true"
        (searchValueChanged)="onSearchValueChanged($event)"
        (isAllProjectsChanged)="onIsAllProjectsChanged($event)"
      ></sm-select-model-header>
    </div>
    <div class="space-line"></div>
    <sm-models-table
      class="model-info-container align-self-stretch"
      selectionMode="single"
      [enableMultiSelect]="false"
      [minimizedView]="false"
      [models]="models$ | async"
      [tableCols]="tableCols"
      [noMoreModels]="noMoreModels$ | async"
      [users]="users$ |async"
      [frameworks]="frameworks$ |async"
      [tags]="tags$ | async"
      [projects]="(projectsOptions$ | async)"
      [systemTags]="systemTags$ | async"
      [tableSortFields]="(tableSortFields$ | async)"
      [tableSortOrder]="(tableSortOrder$ | async)"
      [tableFilters]="(tableFilters$ | async)"
      [selectedModels]="selectedModels$ | async"
      [onlyPublished]="true"
      [colRatio]="0.75"
      (modelSelectionChanged)="modelSelectionChanged($event)"
      (sortedChanged)="sortedChanged($event)"
      (filterChanged)="filterChanged($event)"
      (loadMoreModels)="getNextModels()"
    >
    </sm-models-table>
  </div>
</sm-dialog-template>
