<sm-overlay [backdropActive]="backdropActive$|async"></sm-overlay>
<sm-editable-section #parameterSection
                     class="light-theme"
                     *ngIf="(selectedSectionHyperParams$| async).length>0 || propSection"
                     [editable]="(!isExample) && ((editable$ | async) || propSection)"
                     [disableSave]="!executionParamsForm?.hyperParameters?.form?.valid"
                     [saving]="saving$ | async"
                     (cancelClicked)="cancelHyperParametersChange()"
                     (saveClicked)="sectionSaved()"
                     (activateEditClicked)="activateEditChanged('hyperparams')">
  <sm-section-header [label]="(selectedSection | replaceViaMapPipe:sectionReplaceMap) | uppercase "></sm-section-header>
  <sm-search search-button
             #search
             class="table-search"
             [value]="searchedText"
             [enableNavigation]="true"
             [minimumChars]="1"
             [debounceTime]="0"
             [expandOnHover]="true"
             [searchResultsCount]="searchResultsCount"
             [searchCounterIndex]="executionParamsForm.matchIndex"
             (valueChanged)="searchTable($event)"
  ></sm-search>
  <sm-experiment-execution-parameters
    #executionParamsForm
    class="form-section"
    [searchedText]="searchedText"
    [editable]="(!isExample) && ((editable$ | async) || propSection) && parameterSection.inEditMode"
    [section]="selectedSection"
    [formData]="(selectedSectionHyperParams$ | async) | sort:'name'"
    (formDataChanged)="onFormValuesChanged($event)"
    (searchCounterChanged)="searchCounterChanged($event)"
    (resetSearch)="search.clear(false)"
    (scrollToResultCounterReset)="scrollIndexCounterReset()"
  ></sm-experiment-execution-parameters>
</sm-editable-section>
