<sm-dialog-template iconClass="al-icon al-ico-trash" [header]="header" [displayX]="false">
  <ng-container *ngIf="!inProgress; else inProgressTemplate">
    <div class="text-center">Are you sure you want to delete <b [innerHTML]="entityName"></b>? <br/>
      {{bodyMessage}}</div>
    <br/>
    <br/>
    <div class="buttons">
      <button cdkFocusInitial (click)="delete()"
              class="btn btn-neon yes-button">DELETE
      </button>
      <button (click)="closeDialog(false)"
              class="btn btn-outline-neon">CANCEL
      </button>
    </div>
  </ng-container>


  <ng-template #inProgressTemplate>

    <div>Deleting <span *ngIf="progressPercent > 0">{{progressPercent}}%</span></div>
    <mat-progress-bar [mode]="progressPercent? 'determinate' : 'indeterminate'"
                      [value]="progressPercent">
    </mat-progress-bar>

    <ng-container *ngIf="totalFilesNumber > 0">
    <div *ngIf="!showFinishMessage; else successMessage" class="progress-message">
      Deleting artifacts. This may take some time. Please do not close the window.
    </div>
    <ng-template #successMessage>
      <div class="progress-message success">
        <i class="al-icon al-ico-success sm-md mr-2"></i>The artifacts were deleted
      </div>
    </ng-template>
    </ng-container>

    <div [class.visible]="(failedEntities$ | async)?.length > 0" class="files-list-container">
      <div (click)="openToggleEntities()" class="pointer">
        <i class="more-info-icon fa fa-angle-right" [ngClass]="{'info-open': isOpenEntities}"></i>The following {{entityType}}s were not deleted:
      </div>
      <div class="more-info" [ngClass]="{'info-open': isOpenEntities}">
        <ul class="list-container">
          <li *ngFor="let entity of (failedEntities$ | async)">
            <div>{{entity.name}}</div>
            <div class="error-reason">{{entity.message}}</div>
          </li>
        </ul>
      </div>
    </div>

    <div [class.visible]="failedFiles?.length > 0" class="files-list-container">
      <div (click)="openToggle()" class="pointer">
        <i class="more-info-icon fa fa-angle-right" [ngClass]="{'info-open': isOpen}"></i>The following artifacts were not deleted:
      </div>
      <div class="more-info mono" [ngClass]="{'info-open': isOpen}">
        <sm-copy-clipboard label="" [clipboardText]="failedFiles.join('\n')"
                           class="copy pl-2 light"
        ></sm-copy-clipboard>
        <a></a>
        <div *ngFor="let file of failedFiles | slice:0:1000">
          <div>{{file}}</div>
        </div>
        <div *ngIf="failedFiles.length > 1000">** Additional artifacts may be left **</div>
      </div>
    </div>
    <br><br>
    <div class="buttons">
      <button (click)="closeDialog(true)"
              class="btn btn-neon" [disabled]="progressPercent < 100">Close
      </button>
    </div>
  </ng-template>

</sm-dialog-template>
