<sm-card class="project-card"
         (click)="projectClicked()"
         [isExample]="!['All Experiments'].includes(project.name) && !isRootProject && (!project.company || ! project.company.id)"
         [isFolder]="true"
         [oneTabMode]="!(project?.sub_projects?.length>0)"
         [subFolderTitle]="project?.sub_projects?.length + ' sub projects'"
>
  <div secondTab class="h-100">
    <div class="card-sp-list" smClickStopPropagation>
      <ul>
        <li *ngFor="let subProject of project?.sub_projects"><a class="d-flex align-items-center"
                                                                 (click)="subProjectClicked(subProject.id)"
                                                                [smTooltip]="subProject?.name"
                                                                matTooltipPosition="above"
                                                                [innerHTML]="subProject?.name | breadcrumbsEllipsis"></a>
        </li>
      </ul>
    </div>
  </div>
  <div header-content>
    <div class="d-flex justify-content-between align-items-center card-name">
      <sm-inline-edit #projectName
                      class="title edit-name dark"
                      [originalText]="project.name | shortProjectName"
                      [editable]="true"
                      pattern="^[^/]+$"
                      [inlineDisabled]="true"
                      (textChanged)="prepareProjectNameForChange($event)"
                      (inlineActiveStateChanged)="projectNameEditActiveChanged($event)"

      >
        <span class="project-name"
              matTooltipPosition="above"
              [smTooltip]="project.name"
        >{{project.name | shortProjectName}}</span>
      </sm-inline-edit>
      <div *ngIf="project.system_tags?.includes('default')" class="justify-content-end project-tag">Sample</div>
      <sm-project-card-menu-extended
        class="menu-wrapper"
        *ngIf="!hideMenu"
        (deleteProjectClicked)="deleteProjectClicked.emit($event)"
        (moveToClicked)="moveToClicked.emit($event)"
        (newProjectClicked)="newProjectClicked.emit($event)"
        (projectNameInlineActivated)="projectName.inlineActivated()"
        [project]="project"
      >
      </sm-project-card-menu-extended>
    </div>

  </div>
  <div class="d-flex justify-content-around w-100">
    <ng-template></ng-template>
    <sm-circle-counter
      [counter]="project.id === '*'? '∞' : project.stats?.active?.total_tasks"
      label="TOTAL"
      [type]="project.stats?.active?.total_tasks===0 ? circleTypeEnum.empty : circleTypeEnum.pending"></sm-circle-counter>
    <sm-circle-counter
      [counter]="project.id === '*'? '∞' : project.stats?.active?.status_count?.in_progress"
      label="RUNNING"
      [type]="project.stats?.active?.status_count?.in_progress ===0 ? circleTypeEnum.empty : circleTypeEnum.running"></sm-circle-counter>
    <sm-circle-counter [counter]="project.id === '*'? '∞' : project.stats?.active?.completed_tasks_24h"
                       label="COMPLETED"
                       [type]="project.stats?.active?.completed_tasks_24h ===0 ? circleTypeEnum.empty : circleTypeEnum.completed"
                       underLabel="(24 hrs)"></sm-circle-counter>
  </div>
  <div footer>
    <div class="footer-title">
      COMPUTE TIME: {{computeTime}}
    </div>
  </div>
</sm-card>
