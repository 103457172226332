<div class="w-100 model-header">
  <sm-model-header
    [minimizedView]="minimizedView"
    [isArchived]="isArchived$ | async"
    [disableCreateNewButton]="isExampleProject"
    [tableCols]="filteredTableCols$ | async"
    [sharedView]="isSharedAndNotOwner$|async"
    [tableFilters]="tableFilters$ | async"
    [metadataKeys]="metadataKeys$ | async"
    [tableMode]="firstModel && (tableMode$ | async)"
    [rippleEffect]="tableModeAwareness$ | async"
    (isArchivedChanged)="archivedChanged($event)"
    (setAutoRefresh)="setAutoRefresh($event)"
    (selectedTableColsChanged)="selectedTableColsChanged($event)"
    (addOrRemoveMetadataKeyFromColumns)="addOrRemoveMetadataKeyFromColumns($event)"
    (removeColFromList)="removeColFromList($event)"
    (selectMetadataKeysActiveChanged)="selectMetadataKeysActiveChanged()"
    (clearTableFilters)="clearTableFiltersHandler($event)"
    (tableModeChanged)="modeChanged($event); tableModeUserAware()"
  ></sm-model-header>
</div>
<div class="models-body" [class.footer-visible]="(selectedModels$ | async)?.length > 1|| (showAllSelectedIsActive$ |async)">
  <as-split #split
            [useTransition]="true"
            [gutterDblClickDuration]="400"
            (gutterClick)="clickOnSplit()"
            (gutterDblClick)="closePanel()"
            (dragEnd)="splitSizeChange($event)"
            (dragStart)="disableInfoPanel()"
            (transitionEnd)="modelsTable.table.resize(); modelsTable.afterTableInit()"
  >
    <as-split-area [size]="100 - splitInitialSize" [visible]="(isSharedAndNotOwner$ | async) !== true">
      <sm-models-table
        #modelsTable
        class="w-100 align-self-stretch"
        selectionMode="single"
        [models]="models$ | async"
        [projects]="(projectsOptions$ | async)"
        [tableCols]="tableCols$ | async"
        [minimizedView]="minimizedView"
        [noMoreModels]="noMoreModels$ | async"
        [users]="users$ |async"
        [frameworks]="frameworks$ |async"
        [tags]="tags$ | async"
        [metadataValuesOptions]="metadataColsOptions$ | async"
        [systemTags]="systemTags$ | async"
        [tableSortFields]="(tableSortFields$ | async)"
        [tableSortOrder]="(tableSortOrder$ | async)"
        [tableFilters]="(tableFilters$ | async)"
        [colsOrder]="(tableColsOrder$ | async)"
        [selectedModels]="selectedModels$ | async"
        [selectedModelsDisableAvailable]="selectedModelsDisableAvailable$ | async"
        [selectedModel]="selectedModel$ | async"
        [split]="selectSplitSize$ | async"
        [enableMultiSelect]="true"
        [entityType]="entityTypeEnum.model"
        (columnsReordered)="columnsReordered($event)"
        (modelSelectionChanged)="modelSelectionChanged($event)"
        (modelsSelectionChanged)="modelsSelectionChanged($event)"
        (tagsMenuOpened)="refreshTagsList()"
        (sortedChanged)="sortedChanged($event)"
        (columnResized)="columnResized($event)"
        (filterChanged)="filterChanged($event)"
        (loadMoreModels)="getNextModels()"
      >
      </sm-models-table>
    </as-split-area>
    <as-split-area [size]="splitInitialSize" class="light-theme" [visible]="minimizedView">
      <router-outlet></router-outlet>
    </as-split-area>
  </as-split>
</div>
<sm-entity-footer
 [footerState]="footerState$ | async"
 [footerItems]="footerItems"
 (footerItemClick)="onFooterHandler($event)"
 (tagSelected)="tagSelected($event, 'models')"
>
</sm-entity-footer>
