<sm-dialog-template [header]="'Choose compared experiments'">
  <div class="select-experiments-container light-theme" (click)="closeSearch()">
    <div class="search-experiment-for-compare d-flex justify-content-between">
      <mat-form-field appearance="outline" class="w-50" smClickStopPropagation>
        <input
          #searchExperiments
          autofocus
          matInput
          name="search-experiments"
          autocomplete="off"
          smClickStopPropagation
          placeholder="Start typing experiment name or ID to add to your comparison"
          (input)="searchTermChanged(searchExperiments.value)"
        >
      </mat-form-field>
      <sm-clear-filters-button
        [tableFilters]="(tableFilters$ | async)"
        (clearTableFilters)="clearTableFilters()"
      ></sm-clear-filters-button>
    </div>
    <div class="hide-table show-table">
      <sm-experiments-table
        class="align-self-stretch"
        [selectionReachedLimit]="reachedCompareLimit"
        [selectionMode]="null"
        [reorderableColumns]="false"
        [minimizedView]="false"
        [colsOrder]="(tableColsOrder$ | async)"
        [tableCols]="tableCols$| async"
        [users]="users$ | async"
        [projects]="projects$ | async"
        [selectedExperiments]="selectedExperimentsIds | idToObjectsArray"
        [experiments]="experiments$ | async"
        [experimentTypes]="types$ | async"
        [tableFilters]="(tableFilters$ | async)"
        [tableSortFields]="(tableSortFields$ | async)"
        [tableSortOrder]="(tableSortOrder$ | async)"
        [tags]="tags$ | async"
        [hyperParamsOptions]="hyperParamsOptions$ | async"
        [activeParentsFilter]="activeParentsFilter$ | async"
        [parents]="parent$ | async"
        [noMoreExperiments]="noMoreExperiments$ | async"
        [systemTags]="systemTags$ | async"
        [entityType]="entityTypes.experiment"
        (tagsMenuOpened)="refreshTagsList()"
        (typesMenuOpened)="refreshTypesList()"
        (experimentsSelectionChanged)="experimentsSelectionChanged($event)"
        (loadMoreExperiments)="getNextExperiments()"
        (sortedChanged)="sortedChanged($event)"
        (filterChanged)="filterChanged($event)"
        (columnResized)="resizeCol($event)"
      >
      </sm-experiments-table>
    </div>
  </div>
  <div class="buttons">
    <button type="submit"
            (click)="applyChanges()"
            class="btn btn-neon yes-button">
      APPLY
    </button>
    <button (click)="closeDialog()" class="btn btn-outline-neon">
      CANCEL
    </button>
  </div>

</sm-dialog-template>
