<sm-dialog-template [closeOnX]="false" (xClicked)="closeDialog(false)" header="Enqueue Experiment" iconClass="al-color blue-300 al-ico-enqueue">
  <ng-form #enqueueForm="ngForm" class="d-flex flex-column light-theme">
    <p *ngIf="reference">
      <b [smTooltip]="reference.length > 80 ? reference : undefined">{{reference.length > 80 ? (reference | slice:0:77) + '...' : reference }}</b>
      will be scheduled for execution through the selected queue.
    </p>

    <p class="mt-4 mb-4 text-danger enqueue-warning" *ngIf="enqueueWarning$ | async">
      <b>Note: Some experiments seems to not contain any executable code.
        <br>If enqueued to run, they might produce no results.</b>
    </p>
    <mat-form-field appearance="outline"
                    class="thin short"
                    floatLabel="always">

      <mat-label class="field-label">Queue</mat-label>
      <input type="text"
             matInput
             smRequiredAutocompleteSelectionValidator
             #autoComplete="ngModel"
             name="queue"
             [(ngModel)]="selectedQueue"
             [matAutocomplete]="auto">
      <mat-autocomplete
        panelClass="light-theme"
        class="light-theme"
        [displayWith]="displayFn"
        #auto="matAutocomplete"
        autoActiveFirstOption
      >
        <mat-option
          class="item"
          *ngIf="(userAllowedToCreateQueue$ | async) &&(autoComplete.control.value && !(displayFn(autoComplete.control.value) | stringIncludedInArray:queuesNames))"
          [value]="{name:autoComplete.control.value}"
        >"{{autoComplete.value}}" <span class="new">(Create New)</span></mat-option>
        <mat-option
          *ngFor="let option of queues  | filter:autoComplete.control.value: 'name'"
          [value]="option"
          [smTooltip]="option.name" smShowTooltipIfEllipsis
          [innerHTML]="$any(option)?.name | highlightSearchText:autoComplete.control.value"></mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="enqueueForm?.getControl(autoComplete)?.errors && !enqueueForm?.getControl(autoComplete)?.errors['requiredAutocompleteSelection'] && enqueueForm?.getControl(autoComplete)?.errors['required'] ">
        Please provide a value
      </mat-error>
    </mat-form-field>

  </ng-form>
  <div class="buttons">
    <button (click)="closeDialog(false)" class="btn btn-outline-neon">
      CANCEL
    </button>
    <button (click)="closeDialog(true)" class="btn btn-neon yes-button" [disabled]="enqueueForm.invalid">
      ENQUEUE
    </button>
  </div>
</sm-dialog-template>
