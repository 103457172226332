<div class="input-model-container">
  <sm-labeled-row label="MODEL NAME">
    <div class="d-flex justify-content-between">
      <span>
        <a [href]="'projects/' + projectId + '/models/' + model?.id" target="_blank"><span>{{model?.name}}</span></a>
        <i *ngIf="model?.uri && !isLocalFile"
           class="pointer fa fa-download ml-2"
           (click)="artifactFilePathClicked(model.uri)"
        ></i>
        <sm-copy-clipboard *ngIf="model?.uri && isLocalFile"
                           label="" [clipboardText]="model.uri"
                           class="pl-2 light"
                           [hideBackground]="true"
        ></sm-copy-clipboard>
      </span>
      <div *ngIf="editable">
        <i class="al-icon al-ico-edit al-color blue-300 sm-md pointer mr-2"  smTooltip="Select model" (click)="chooseModel()"></i>
        <i *ngIf="model?.id" class="al-icon al-ico-trash al-color blue-300 sm-md pointer" smTooltip="Remove model" (click)="removeModel()"></i>
      </div>
    </div>
  </sm-labeled-row>
  <sm-labeled-row *ngIf="showCreatedExperiment" label="CREATING EXPERIMENT">
    <a [href]="'/projects/'+ ((source?.projectId) ? source?.projectId : '*') + '/experiments/' + (source? source?.experimentId: '')"
       target="_blank">
      {{source?.experimentName}}
    </a>
  </sm-labeled-row>
</div>
