<div *ngIf="noGraphs" class="no-output" >
  <i class="icon no-output-icon" [ngClass]="isDarkTheme ? 'i-no-plots-dark' : 'i-no-plots'"></i>
  <h3>NO CHART DATA</h3>
</div>
<div class="metrics-section">
  <div #allMetrics
       class="all-metrics"
       [class.row]="!isGroupGraphs"
  >
    <div *ngFor="let metric of (graphList | filter: hiddenList); trackBy: trackByFn" class="metric-group-container less-padding"
         #metricGroup
         [class.two-in-a-row]="!isGroupGraphs && graphList.length > 1 && isWidthBigEnough()"
         [style.height.percent]=""
    >
      <div [id]="EXPERIMENT_GRAPH_ID_PREFIX + metric" class="graph-id">
        <div>
          <div class="metric-group-header" *ngIf="isGroupGraphs">{{metric}}</div>
          <div [class.row]="isGroupGraphs"
               [class.single-graph]="graphsData[metric].length === 1">
            <ng-container *ngFor="let chartItem of graphsData[metric];trackBy: trackByIdFn.bind(this, metric)">
              <sm-single-graph
                #singleGraph
                [class.less-padding]="isGroupGraphs"
                [class.two-in-a-row]="isGroupGraphs && chartItem?.data[0]?.type !== 'table' && graphsData[metric].length > 1 && isWidthBigEnough()"
                [graphsNumber]="graphsData[metric].length"
                [legendStringLength]="legendStringLength"
                [chart]="chartItem"
                [id]="SINGLE_GRAPH_ID_PREFIX + EXPERIMENT_GRAPH_ID_PREFIX + metric + chartItem.layout.title + chartItem.iter + chartItem.variant + (chartItem.layout.images && chartItem.layout.images[0]?.source)"
                [isCompare]="isCompare"
                [disableResize]="disableResize"
                [isDarkTheme]="isDarkTheme"
                [showLoaderOnDraw]="showLoaderOnDraw"
                [legendConfiguration]="legendConfiguration"
                [smoothWeight]="smoothWeight"
                [xAxisType]="xAxisType"
                [height]="height"
                [moveLegendToTitle]="groupBy === groupByCharts.None"
                [identifier]="SINGLE_GRAPH_ID_PREFIX + EXPERIMENT_GRAPH_ID_PREFIX + metric + chartItem.layout.title + chartItem.iter + chartItem.variant + (chartItem.layout.images && chartItem.layout.images[0]?.source)"
                (resizeStarted)="resizeStarted(metricGroup, singleGraph)"
                (resizing)="onResizing($event)"
                (sizeChanged)="sizeChanged($event)">
              </sm-single-graph>
              <div class="resize-overlay" [class.active]="activeResizeElement && activeResizeElement !== singleGraph"></div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

