<div class="d-flex justify-content-between header-container align-items-center"
     [ngClass]="{'archive-mode': isArchived}">
  <div class="d-flex-center">
    <sm-toggle-archive
      *ngIf="!hideArchiveToggle"
      [class.hide-item]="sharedView"
      [showArchived]="isArchived"
      [minimize]="(isSmallScreen$ | async).matches"
      (toggleArchived)="onIsArchivedChanged($event)"></sm-toggle-archive>
    <sm-button-toggle
      class="ml-3"
      [disabled]="!tableMode"
      [options]="[{label: 'Table view', value: 'table', icon: 'al-ico-table-view'}, {label: 'Details view', value: 'info', icon: 'al-ico-experiment-view', ripple: true}]"
      [value]="tableMode"
      [rippleEffect]="rippleEffect"
      (valueChanged)="tableModeChanged.emit($event)"></sm-button-toggle>
  </div>
  <sm-project-context-navbar
    *ngIf="!hideCreateNewButton"
    [class.hide-item]="sharedView"
    [activeFeature]="'models'"
    [archivedMode]="isArchived">
  </sm-project-context-navbar>
  <div class="d-flex justify-content-end align-items-center right-buttons">
    <sm-clear-filters-button
      [tableFilters]="tableFilters"
      (clearTableFilters)="clearTableFilters.emit(tableFilters)"
    ></sm-clear-filters-button>
    <sm-model-custom-cols-menu
      [disabled]="minimizedView || sharedView"
      [isLoading]="isLoadingMetadataKeys"
      [metadataKeys]="metadataKeys"
      [tableCols]="tableCols"
      (removeColFromList)="removeColFromList.emit($event)"
      (selectedTableColsChanged)="selectedTableColsChanged.emit($event)"
      (selectMetadataKeysActiveChanged)="selectMetadataKeysActiveChanged.emit($event)"
      (addOrRemoveMetadataKeyFromColumns)="addOrRemoveMetadataKeyFromColumns.emit($event)"
    ></sm-model-custom-cols-menu>
    <sm-refresh-button
      (setAutoRefresh)="setAutoRefresh.emit($event)"
    >
    </sm-refresh-button>
  </div>
</div>

